import React from 'react'
import './App.css'
import NavBar from './Comp/NavBar';
import Navpath from './Comp/NavPath';
import { BrowserRouter} from "react-router-dom";

function App() {

  return (
    <div className='App'>
    <BrowserRouter>
    <NavBar />
    <Navpath />
    </BrowserRouter>
    </div>
  );
}

export default App;