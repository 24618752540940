import {React,useEffect} from 'react';
import '../Styles/Footer.css';
import AOS from 'aos';
import 'aos/dist/aos.css';





function Footer() {
  const today = new Date();
  const dayOfWeek = today.toLocaleDateString('en-US', {weekday: 'long'});
  const greeting = `Thank you for visiting and have a great ${dayOfWeek}!`;

  useEffect(() => {
    AOS.init({
      duration: 1750,
      once: true,
    });
  }, []);


  return (
    <div className='footer'>
      <div className='copy'>
        
        <h1 data-aos='fade-in'>{greeting}</h1>
        <p> © Paul Joplin 2024 </p>
      </div>
    </div>
  )
}

export default Footer;