import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import '../Styles/NavBar.css';
import * as CgIcons from 'react-icons/cg'
import { SidebarMap } from './SidebarMap';
import { IconContext } from 'react-icons/lib';



  function NavBar() {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
      setSidebar(!sidebar);
      if (!sidebar) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'initial';
      }
    };
    
     /*Navmenu closes when used clicks outside of menu*/
    useEffect(() => {
      const Sidebar = document.querySelector('.nav-menu');
      const handleClickOutside = e => {
        if (!sidebar || !Sidebar.contains(e.target)) {
          setSidebar(false);
          document.body.style.overflow = 'initial';
        }
      };
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      }
    }, [sidebar]);
  

    /*Navbar changes color on scroll down */
    window.onscroll = function() {
      var navbar = document.querySelector(".navbar");
      if (window.pageYOffset > 0) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    return (
    <IconContext.Provider value={{color:'white'}}>
    <div className='navbar'>

          <p>PJ</p>
          
        <Link to='#' className='menu-bars'>
          <CgIcons.CgMenuRight color='white' onClick={showSidebar}/>
        </Link>
      <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className='nav-menu-items' onClick={showSidebar}>
          <div className='nav-title'><h3>Menu</h3></div>
          {SidebarMap.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            );
            })}
        </ul>
      </nav>
    </div>
    </IconContext.Provider>
  );
}
export default NavBar