import {React,useEffect} from 'react'
import '../Styles/skillslider.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import {ReactLogo, Html5, Css3, Javascript,Github, VisualStudio, Aws} from '@styled-icons/boxicons-logos';
import {Cpu} from '@styled-icons/bootstrap/Cpu'
import { MegaphoneFill } from 'styled-icons/bootstrap';
import { EarListen, Brain } from 'styled-icons/fa-solid';
import { Eye } from 'styled-icons/evil';
import { Organization } from 'styled-icons/fluentui-system-filled';
import { Results } from 'styled-icons/foundation';
import { ChatboxEllipses } from 'styled-icons/ionicons-sharp';
import { PriorityHigh } from 'styled-icons/material';


const Skillslider = () => {

  useEffect(() => {
    AOS.init({
      duration: 1750,
      once: true,
    });
  }, []);

  return (
    <div data-aos='fade-up' className='skill-slider'>
      <h1>Technical Skills</h1>
        <div className='tech-logos'>
          <div className='tech-logo'>
            <ReactLogo size='55' color="lightblue" />
            <div className='logo-title'>React</div>
          </div>
          <div className='tech-logo'>
            <Html5 size='55' color="red" />
            <div className='logo-title'>HTML</div>
          </div>
          <div className='tech-logo'>
            <Css3 size='55' color="cyan" />
            <div className='logo-title'>CSS</div>
          </div>
          <div className='tech-logo'>
            <Javascript size='55' color="yellow" />
            <div className='logo-title'>JavaScript</div>
          </div>
          <div data-aos='fade-up' className='tech-logo'>
            <Github size='55' color="purple" />
            <div className='logo-title'>GitHub</div>
          </div>
          <div data-aos='fade-up' className='tech-logo'>
            <VisualStudio size='55' color="blue" />
            <div className='logo-title'>Visual Studio</div>
          </div>
          <div data-aos='fade-up' className='tech-logo'>
            <Cpu size='55' color="lightgreen" />
            <div className='logo-title'>Custom Builds</div>
          </div>
          <div data-aos='fade-up' className='tech-logo'>
            <Aws size='55' color="white" />
            <div className='logo-title'>Hosting</div>
          </div>
        </div>
        
        <h2 data-aos='fade-up'>Interpersonal Skills</h2>
        <div className='personal-logos'>
        <div data-aos='fade-up' className='personal-logo'>
            <MegaphoneFill size='45' color="lightblue" />
            <div className='logo-title'>Leader</div>
          </div>
          <div data-aos='fade-up' className='personal-logo'>
            <PriorityHigh size='45' color="red" />
            <div className='logo-title'>Effective Prioritizing</div>
          </div>
          <div data-aos='fade-up' className='personal-logo'>
            <Eye size='55' color="cyan" />
            <div className='logo-title'>Detail-Oriented</div>
          </div>
          <div data-aos='fade-up' className='personal-logo'>
            <Organization size='45' color="yellow" />
            <div className='logo-title'>Organized</div>
          </div>
          <div data-aos='fade-up' className='personal-logo'>
            <Results size='45' color="purple" />
            <div className='logo-title'>Results Driven</div>
          </div>
          <div data-aos='fade-up' className='personal-logo'>
            <EarListen size='45' color="blue" />
            <div className='logo-title'>Active Listener</div>
          </div>
          <div data-aos='fade-up' className='personal-logo'>
            <ChatboxEllipses size='45' color="lightgreen" />
            <div className='logo-title'>Skilled Communication</div>
            </div>
            <div data-aos='fade-up' className='personal-logo'>
            <Brain size='45' color="white" />
            <div className='logo-title'>Open-Minded</div>
          </div>
        </div>
        </div>
  );
}

export default Skillslider