import * as BsIcons from 'react-icons/bs'
import * as FaIcons from 'react-icons/fa'


export const SidebarMap = 
[
    {
    title: 'Home',
    path: '/',     
    icon: <FaIcons.FaHome />,
    cName: 'nav-text',
    },
    {
    title: 'Résumé',
    path: '/history', 
    icon: <BsIcons.BsFileCode/>,
    cName: 'nav-text'    
    }
]