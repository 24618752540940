import React from 'react'
import '../Styles/History.css'

import { useEffect } from 'react';


function History() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    
<div class="resume">
<iframe src="https://onedrive.live.com/embed?resid=967F444AC1103BB3%2128479&authkey=!AG279HDyXsWHZSo&em=2" width="476" height="288" frameborder="0" scrolling="no"></iframe>
  <a href="https://onedrive.live.com/embed?resid=967F444AC1103BB3%2128479&authkey=!AG279HDyXsWHZSo&em=2" download class="resume-link">Download my résumé</a>
</div>
  )

}

export default History;