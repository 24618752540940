import React from 'react'
import Home from '../Pages/Home';
import History from '../Pages/History';
import { Routes, Route} from "react-router-dom";

function navpath() {
    return (
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/history" element={<History />} /> 
        </Routes>
         )
}

export default navpath